<style lang="scss">
#frontend {
  footer {
    margin-top: 150px;
    $space-height: 60px;

    @media (max-width: $width-sm) {
      $space-height: 80px;
      margin-top: 60px;
    }

    //border:1px solid red;
    [class*='col-'] {
      //border:1px solid blue;
    }

    .headline-section {
      @media (max-width: $width-md) {
        margin-bottom: 20px;
      }

      @media (max-width: $width-sm) {
        text-align: center;
      }
    }

    .headline-section-padding {
      margin-bottom: 20px;
      //font-family:"Helvetica Neue LT W05_75 Bold";
      //font-family:"Helvetica Neue LT W05 56 Italic";
      letter-spacing: $letter-spacing-std;
    }

    h4 {
      text-transform: uppercase;
    }

    .kontakt-top {
      position: relative;
      top: -40px;
      padding-bottom: $space-height;

      @media (max-width: $width-sm) {
        text-align: center;
        top: 0px;
      }

      .text-center-md {
        @media (max-width: $width-md) {
          text-align: center;
          margin-top: 10px;
        }
      }

      .button-footer {
        margin-top: 20px;
        display: inline-block;
        color: #fff;
        background: #000;
        padding: 12px 30px 10px 30px;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 1;
      }
    }

    .kontakt-links {
      padding-bottom: 40px;

      @media (max-width: $width-sm) {
        text-align: center;
      }

      .space-kontakt-link {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .space-kontakt-link-v2 {
        margin-top: 30px;
      }

      h4 {
        font-family: 'Helvetica Neue LT W05 85 Heavy';
        letter-spacing: 1px;
        //font-family:"Helvetica Neue LT W05 65 Medium";
      }

      a {
        font-family: 'Helvetica Neue LT W05 55 Roman';
        display: inline-block;
        color: #000;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .social-media {
      padding-bottom: $space-height;

      @media (max-width: $width-sm) {
        text-align: center;
        padding-bottom: 30px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          a {
            font-family: 'Helvetica Neue LT W05 55 Roman';
            color: #000;
            display: inline-block;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }

    .img-box-footer {
      margin-top: 50px;
      width: 100%;
      height: 450px;
      background-size: cover;
      background-position: bottom center;

      @media (max-width: $width-md) {
        height: 300px;
        margin-top: 30px;
      }
    }

    .footer-navigation {
      background: #f0f0f0;
      padding: 20px 0;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        @media (max-width: $width-sm) {
          display: block;
        }

        li {
          @media (max-width: $width-sm) {
            text-align: center;
          }

          a {
            //font-family:"Helvetica Neue LT W05 65 Medium";
            display: inline-block;
            padding: 10px 15px;
            letter-spacing: 3px;
            color: #000;
            text-transform: uppercase;
            margin: 0 20px;
            font-size: 90%;

            @media (max-width: $width-lg) {
              margin: 0 10px;
            }

            @media (max-width: $width-md) {
              margin: 0 5px;
            }

            @media (max-width: $width-sm) {
              margin: 0 5px;
            }

            &:hover {
              opacity: 0.7;
            }
          }

          .router-link-active {
            //border:1px solid red;
          }
        }
      }
    }

    .socket {
      background: #000000;
      padding: 20px;
      text-align: center;

      div {
        font-family: 'Helvetica Neue LT W05 65 Medium';
        color: #fff;
        display: inline-block;

        font-size: 12px;
        letter-spacing: 1px;

        .breaker {
          @media (max-width: 530px) {
            display: block;
            height: 1px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <footer>
    <!--<div class="container">
      <div class="row header-section-box justify-content-center align-self-center">
        <div class="col-lg-3 col-md-4 justify-content-center align-self-center">
          <div class="headline-section">kontakt</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1 v-html="$t('General.wir-freuen-uns-auf-sie')"></h1>
        </div>
      </div>
      <div class="row kontakt-top  justify-content-center align-self-center">
        <div class="col-lg-3 col-md-4 justify-content-center align-self-center"></div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center text-center-md">
          <router-link :to="{ name: 'Home' }" class="button-footer">Kontaktieren Sie uns</router-link>
        </div>
      </div>
      <div class="row kontakt-links justify-content-center align-self-center">
        <div class="col-lg-3 col-md-4 col-sm-5">
          <div class="headline-section headline-section-padding">find &amp; mail us</div>
        </div>
        <div class="col-lg-6 col-md-8 col-sm-7 justify-content-center align-self-center">
          <div class="row ">
            <div class="col-lg-4">
              <h4>Austria</h4>
              <a href="https://goo.gl/maps/dkotwVWaD6XecNx59" target="_blank">Gadollaplatz 1<br />8010 Graz</a><br />
              <a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a>
            </div>
          </div>
          <div class="row space-kontakt-link">
            <div class="col-lg-5 space-kontakt-link">
              <h4>Croatia</h4>
              <a href="https://goo.gl/maps/sE1S4VasAcQf66nh6" target="_blank">Oreškovićeva 6H/1<br />10000 Zagreb</a><br />
              <a href="mailto:uprava@styria.hr">uprava@styria.hr</a>
            </div>
            <div class="col-lg-5 space-kontakt-link-v2">
              <h4>Slovenia</h4>
              <a href="https://goo.gl/maps/twEVjVXADQJQMXGC9" target="_blank">Verovškova ulica 55<br />1000 Ljubljana</a><br />
              <a href="mailto:mediagroup@styria.com">mediagroup@styria.com</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row social-media justify-content-center align-self-center">
        <div class="col-lg-3 col-md-4 col-sm-5">
          <div class="headline-section headline-section-padding">follow us</div>
        </div>
        <div class="col-lg-6 col-md-8 col-sm-7">
          <div class="row">
            <div class="col-lg-6">
              <ul>
                <li><router-link :to="{ name: '' }">LinkedIn</router-link></li>
                <li><router-link :to="{ name: '' }">Instagram</router-link></li>
                <li><router-link :to="{ name: '' }">Facebook</router-link></li>
                <li><router-link :to="{ name: '' }">Youtube</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="img-box-footer" :style="{ 'background-image': `url(${require('@/assets/img/footer-img-styria.jpg')})` }"></div>-->
    <div class="footer-navigation">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-center align-self-center">
            <ul class="list-group list-group-horizontal ">
              <li>
                <router-link :to="{ name: 'offenlegung' }">{{ $t('General.offenlegung') }}</router-link>
              </li>
              <li>
                <a :href="path" target="_blank">{{ $t('General.datenschutz') }}</a>
              </li>
              <li>
                <router-link :to="{ name: 'kontakt' }">{{ $t('General.kontakt') }}</router-link>
              </li>
              <li>
                <a href="javascript:Didomi.preferences.show()">{{ $t('General.consentchoices') }}</a>
              </li>
              <!--<li><router-link :to="{ name: 'presskit' }">{{$t('General.presskit')}}</router-link></li>-->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="socket">
      <div class="container">
        <div class="row">
          <div class="col-12 justify-content-center align-self-center">
            <div>© 2023 Styria Media Group AG, <span class="breaker"></span>all rights reserved</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { mdiChevronDoubleLeft } from '@mdi/js';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const i18n = useI18n();

    const path = computed(() => `${store.getters.getHomeBase}datenschutz_${i18n.locale.value}.html`);

    const currentRoute = ref(router.currentRoute);

    return {
      path,
      currentRoute,
      mdiChevronDoubleLeft,
    };
  },
});
</script>
